import React from "react";
import { graphql } from "gatsby";

import { Base } from "../components/Base";
import SEO from "../components/seo";
import illus404 from "../components/illus404.svg";

class NotFoundPage extends React.Component {
  render() {
    return (
      <Base>
        <SEO title="Page introuvable" />
        <div className="max-w-6xl px-2 mx-auto mb-48">
          <h1 className="text-4xl font-black mt-16 mb-8 text-center">
            Page introuvable
          </h1>
          <div className="flex justify-center flex-col">
            <img
              className="py-4 m-0 h-80"
              src={illus404}
              alt="Omnidoc, plateforme de télé-expertise"
            />
            <div className="mt-16 flex justify-center">
              <a
                className="transition duration-500 text-lg rounded-full shadow border border-action bg-action hover:bg-action-600 hover:shadow-lg text-white font-medium inline-block px-12 py-2 "
                href="/"
              >
                Revenir à l'accueil
              </a>
            </div>
          </div>
        </div>
      </Base>
    );
  }
}

export default NotFoundPage;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
